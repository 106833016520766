import React from 'react'

import './TermsOfService.scss'

import { tosContent } from './termsOfServiceContent'
const TermsOfService = ({
    heading=''
}) => {
    return (
        <div
            className='termsOfServiceContent'
        >
            { heading && <h1>{heading}</h1>}
            <div
                className='termsOfServiceContentContainer'
            >
                {
                    tosContent.map(item => {
                        return(
                            <span>
                                {item.headline && <h2>{item.headline}</h2>}
                                {
                                    item.content.map(description => <p dangerouslySetInnerHTML={{__html: description}}/>)
                                }
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TermsOfService
